<template>
  <section id="dashboard-main" class="mt-2">
    <!-- {{ item }} -->
    <!-- <b-row class="match-height">
        <b-col
        xl="12"
        lg="12"
        md="12"
        class="ecs-card"
        >
          <codec-sttus-list :items="item"></codec-sttus-list>
        </b-col>
    </b-row> -->
    <!-- 코덱 전체 리스트 중 Fault/Usage만 사용 중인 코덱 리스트 -->
    <b-row class="match-height">
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <b-card body-class="p-0">
          <div class="custom-search">
            <div class="m-1">
              <!-- Table Top -->
              <b-row>
                <!-- 타이틀 -->
                <b-col cols="12" xl="12" lg="12" md="12">
                  <h4 class="float-left font-weight-bolder mt-10px">
                    Fault/Usage 사용현황
                  </h4>
                  <!-- 
                      작성일 2021-04-14
                      작성자 박재헌
                      ** 코덱 현황 통계 
                      ** 관련 함수 setCodecStatusTotal()
                     -->
                  <div
                    class="float-right font-weight-bolder"
                    style="font-size: 1.1rem"
                  >
                    <table class="text-center table">
                      <!-- <thead> -->
                      <tr>
                        <th
                          class="table-light"
                          rowspan="2"
                          style="padding: 0px 5px"
                        >
                          코덱 현황
                        </th>
                        <th class="table-light" style="padding: 3px 5px">
                          On/Off
                        </th>
                        <th class="table-light" style="padding: 3px 5px">
                          Fault
                        </th>
                        <th class="table-light" style="padding: 3px 5px">
                          Usage
                        </th>
                        <!-- <th style="padding: 3px 5px">Total</th> -->
                      </tr>
                      <!-- </thead> -->
                      <!-- <tbody> -->
                      <tr>
                        <td style="padding: 3px 5px">{{ onoffTotal }}</td>
                        <td style="padding: 3px 5px">{{ faultTotal }}</td>
                        <td style="padding: 3px 5px">{{ callTotal }}</td>
                        <!-- <td style="padding: 3px 5px">{{ total }}</td> -->
                      </tr>
                      <!-- </tbody> -->
                    </table>
                  </div>
                </b-col>
                <!-- 타이틀 END -->
              </b-row>
            </div>
          </div>
          <div class="pl-1 pr-1">
            <div class="table-responsive" v-dragscroll>
              <table style="width: 100%">
                <tr style="height: 45px">
                  <th
                    class="text-center"
                    style="
                      border: 1px solid #fefefe;
                      width: 60px;
                      font-weight: 600;
                    "
                  >
                    번호
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 100px"
                  >
                    IP
                  </th>
                  <!-- <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 45px"
                  >
                    Port
                  </th> -->
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 150px"
                  >
                    지역
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 280px"
                  >
                    회의실
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 60px"
                  >
                    회사명
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 120px"
                  >
                    모델명
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 50px"
                  >
                    On/Off
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 50px"
                  >
                    Fault
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 50px"
                  >
                    Usage
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 60px"
                  >
                    Port
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 40px"
                  >
                    리셋
                  </th>
                </tr>
                <tr v-for="(row, i) in FauUsaitems" v-bind:key="i">
                  <td style="border: 1px solid #fefefe">{{ i + 1 }}</td>
                  <td style="border: 1px solid #fefefe">
                    <a
                      :href="`https://${row ? row.ip : ''}`"
                      target="_blank"
                      style="color: #6e6b7b"
                      >{{ row ? row.ip : "" }}</a
                    >
                  </td>
                  <!-- <td style="border: 1px solid #fefefe">
                    <span
                      style="color: #6e6b7b; cursor: pointer"
                      @click="openPortChk(row.ip, row.port)"
                    >
                      {{ row ? row.port : "" }}
                    </span>
                  </td> -->
                  <td style="border: 1px solid #fefefe">
                    {{ row ? row.areaNm : "" }}
                  </td>
                  <td style="border: 1px solid #fefefe">
                    <span
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-detail
                      @click="setDetailProps(row, i, 0)"
                    >
                      {{ row ? row.confereneceNm : "" }}
                    </span>
                  </td>
                  <!-- 비교하여 받은 시간이 1분 이상 차이나면 OFF 해 주세요 -->
                  <!-- <td style="border: 1px solid #fefefe;" class="text-center">{{row.brandNm}}(마지막 받은 시간 {{new Date(row.lastUpdtPnttm).toISOString()}} 60000 초 계산 : {{diffGetTime(row.lastUpdtPnttm)}})</td> -->
                  <td style="border: 1px solid #fefefe" class="text-center">
                    {{ row ? row.brandNm : "" }}
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    {{ row ? row.modelNm : "" }}
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <span
                      class="b-avatar rounded-circle"
                      :class="setIcon(row ? row : [], 'onoff', i, 'faultItems')"
                    ></span>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <span
                      class="b-avatar rounded-circle"
                      :class="[
                        setIcon(
                          row ? row.faultSt : '',
                          'fault',
                          i,
                          'faultItems'
                        ),
                      ]"
                    ></span>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <span
                      class="b-avatar rounded-circle"
                      :class="[
                        setIcon(
                          row ? row.activationSt : 'Off',
                          'usage',
                          i,
                          'faultItems'
                        ),
                      ]"
                    ></span>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <button
                      class="btn-codec"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-setting-new
                      @click="setOpenPortProps(row ? row : {}, i)"
                    >
                      포트확인
                    </button>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <button
                      class="btn-codec"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-setting
                      @click="setSettingProps(row ? row : [], i)"
                    >
                      리셋
                    </button>
                  </td>
                  <!-- <td style="border: 1px solid #fefefe;" class="text-center">
                      <button style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-setting-new
                      @click="setSettingProps(row,i)"
                      >설정</button>
                    </td> -->
                </tr>
                <tr v-if="!FauUsaitems.length" style="height: 45px">
                  <td colspan="10">조회된 목록이 없습니다.</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 코덱 전체 리스트 중 Fault/Usage만 사용 중인 코덱 리스트 끝 -->
          <!-- 코덱 전체 리스트 중 사용 중인 코덱 리스트 -->
          <div class="custom-search">
            <div class="m-1">
              <!-- Table Top -->
              <b-row>
                <!-- 타이틀 -->
                <b-col cols="12" xl="12" lg="12" md="12">
                  <h4 class="w-100 font-weight-bolder mt-8px">
                    코덱 전체 사용현황
                  </h4>
                </b-col>
                <!-- 타이틀 END -->
              </b-row>
            </div>
          </div>
          <div class="pl-1 pr-1">
            <div class="table-responsive" v-dragscroll>
              <table style="width: 100%">
                <tr style="height: 45px">
                  <th
                    class="text-center"
                    style="
                      border: 1px solid #fefefe;
                      width: 60px;
                      font-weight: 600;
                    "
                  >
                    번호
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 100px"
                  >
                    IP
                  </th>
                  <!-- <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 45px"
                  >
                    Port
                  </th> -->
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 150px"
                  >
                    지역
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 280px"
                  >
                    회의실
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 60px"
                  >
                    회사명
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 120px"
                  >
                    모델명
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 50px"
                  >
                    On/Off
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 50px"
                  >
                    Fault
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 50px"
                  >
                    Usage
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 60px"
                  >
                    Port
                  </th>
                  <th
                    class="text-center"
                    style="border: 1px solid #fefefe; width: 40px"
                  >
                    리셋
                  </th>
                </tr>
                <tr v-for="(row, i) in items" v-bind:key="i">
                  <td style="border: 1px solid #fefefe">{{ i + 1 }}</td>
                  <td style="border: 1px solid #fefefe">
                    <a
                      :href="`https://${row ? row.ip : ''}`"
                      target="_blank"
                      style="color: #6e6b7b"
                      >{{ row ? row.ip : "" }}</a
                    >
                  </td>
                  <!-- <td style="border: 1px solid #fefefe">
                    <span
                      style="color: #6e6b7b; cursor: pointer"
                      @click="openPortChk(row.ip, row.port)"
                    >
                      {{ row ? row.port : "" }}
                    </span>
                  </td> -->
                  <td style="border: 1px solid #fefefe">
                    {{ row ? row.areaNm : "" }}
                  </td>
                  <td style="border: 1px solid #fefefe">
                    <span
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-detail
                      @click="setDetailProps(row, i, 0)"
                    >
                      {{ row ? row.confereneceNm : "" }}
                    </span>
                  </td>
                  <!-- 비교하여 받은 시간이 1분 이상 차이나면 OFF 해 주세요 -->
                  <!-- <td style="border: 1px solid #fefefe;" class="text-center">{{row.brandNm}}(마지막 받은 시간 {{new Date(row.lastUpdtPnttm).toISOString()}} 60000 초 계산 : {{diffGetTime(row.lastUpdtPnttm)}})</td> -->
                  <td style="border: 1px solid #fefefe" class="text-center">
                    {{ row ? row.brandNm : "" }}
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    {{ row ? row.modelNm : "" }}
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <span
                      class="b-avatar rounded-circle"
                      :class="setIcon(row ? row : [], 'onoff', i, 'items')"
                    ></span>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <span
                      class="b-avatar rounded-circle"
                      :class="[
                        setIcon(row ? row.faultSt : '', 'fault', i, 'items'),
                      ]"
                    ></span>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <span
                      class="b-avatar rounded-circle"
                      :class="[
                        setIcon(
                          row ? row.activationSt : 'Off',
                          'usage',
                          i,
                          'items'
                        ),
                      ]"
                    ></span>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <button
                      class="btn-codec"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-setting-new
                      @click="setOpenPortProps(row ? row : {}, i)"
                    >
                      포트확인
                    </button>
                  </td>
                  <td style="border: 1px solid #fefefe" class="text-center">
                    <button
                      class="btn-codec"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-setting
                      @click="setSettingProps(row ? row : [], i, 0)"
                    >
                      리셋
                    </button>
                  </td>
                  <!-- <td style="border: 1px solid #fefefe;" class="text-center">
                      <button style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.cd-stu-setting-new
                      @click="setSettingProps(row,i)"
                      >설정</button>
                    </td> -->
                </tr>
                <tr v-if="!items.length" style="height: 45px">
                  <td colspan="10">조회된 목록이 없습니다.</td>
                </tr>
              </table>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- 코덱 전체 리스트 중 사용 중인 코덱 리스트 끝 -->
    <!-- <codec-sttus-list :items="this.item"></codec-sttus-list> -->
    <codec-sttus-setting
      :detailItems="this.detailItem"
      :codecItem="this.codecItem"
      :index="this.index"
      :configSt="this.configSt"
    ></codec-sttus-setting>
    <codec-sttus-setting-new
      :settingItems="this.settingItem"
      :codecItem="this.codecItem"
    ></codec-sttus-setting-new>
    <codec-sttus-detail
      :detailItems="this.detailItem"
      :codecItem="this.codecItem"
      :codecItemType="this.codecItemTypes"
      :setTabs="this.setTab"
      :statusSt="this.statusSt"
    ></codec-sttus-detail>
  </section>
</template>
<script>
// import {
//   BRow, BCol,
// } from 'bootstrap-vue'
import { VBToggle, BRow, BCol, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { dragscroll } from "vue-dragscroll";

import CodecSttusSetting from "./components/CdStuSetting.vue";
import CodecSttusSettingNew from "./components/CdStuSettingNew.vue";
import CodecSttusDetail from "./components/CdStuDetail.vue";

const qs = require("qs");

export default {
  directives: {
    Ripple,
    dragscroll,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    CodecSttusSetting,
    CodecSttusSettingNew,
    CodecSttusDetail,
  },
  data() {
    return {
      nowTime: new Date().getTime(),
      webdata: [],
      data: {},
      ip: "",
      codecUrl: "api/codec/servers/",
      item: [],
      items: [],
      FauUsaitem: [],
      FauUsaitems: [],
      payload: [],
      payloads: [],
      wsItem: [],
      settingItem: {},
      detailItem: {},
      thisFaultCnt: 0,
      codecItem: {},
      index: 0,
      setTab: 1,
      codecItemTypes: "success",

      // 코덱 통계 현황
      callTotal: 0,
      faultTotal: 0,
      onoffTotal: 0,
      total: 0,
      statusSt: false,
      configSt: false,
    };
  },
  watch: {
    items() {
      // this.filterItems(this.items, 'items')
    },
    FauUsaitems(data) {
      data.map((item, idx) => {
        if (item.activationSt === "Off") {
          if (
            item.faultSt === "Off" ||
            item.faultSt === null ||
            item.faultSt === "On"
          ) {
            data.splice(idx, 1);
            // return;
          } else {
            // return;
          }
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getCodec();
    this.wsConect();
    this.$store.commit("CHANG_COMP", 1);
    // console.log(this.item) // 처음 불러오는 data
    // console.log(this.webdata) // 웹소켓 data
  },
  methods: {
    setOpenPortProps(row, i) {
      this.settingItem = {
        data: row,
        index: i,
      };

      this.codecItem = row;
    },
    /**
     * 시간 차이 계산
     * @create 2021-04-26
     * @author 정한슬
     * @description 시간 차이 계산
     */
    diffGetTime(date1) {
      let nowTime = new Date().getTime();
      let intime = new Date(date1).getTime();
      let diff = nowTime - intime;
      let rslt = false;
      if (diff > 300000) {
        rslt = false;
      } else {
        rslt = true;
      }
      return rslt;
      // return diff
    },
    /**
     * 리스트 코덱 상태 뱃지 set
     * @create 2021-04-26
     * @author 박재헌
     * @description 리스트 코덱 상태 뱃지 set
     */
    setIcon(status, label, i, type) {
      // console.log(status, label)
      // console.log('에러 발생 전')
      if (status === null) return "badge-secondary";
      if (label === "onoff") {
        let nowTime = new Date().getTime();
        let intime = new Date(status.lastUpdtPnttm).getTime();
        let diff = nowTime - intime;
        let rslt = false;
        if (diff > 300000) {
          // 현재시간과 데이터 받아온 시간이 5분 이상 차이 났을때 flog false
          rslt = false;
        } else {
          rslt = true;
        }
        if (rslt == true) {
          if (type == "items") {
            // console.log(this.items, '*** items')
            this.filterItems(this.items, type);
          }
          if (status.onoffSt === "On") return "badge-success";
          if (status.onoffSt === "Off") return "badge-secondary";
        } else {
          status.onoffSt = "Off";
          status.usageSt = "Off";
          status.faultSt = "";
          status.activationSt = "Off";
          if (type == "items") {
            // console.log(this.items, '*** items')
            this.filterItems(this.items, type);
          }
          return "badge-secondary";
        }
      } else if (label === "fault") {
        if (type == "items") {
          // console.log(this.items, '*** items')
          this.filterItems(this.items, type);
        }
        if (status === "critical") return "badge-success";
        if (status === "major") return "badge-success";
        if (status === "minor") return "badge-success";
        if (status === "warning") return "badge-success";
        if (status === "error") return "badge-success";
        if (status === "cleared") return "badge-secondary";
        if (status === "") return "badge-secondary";
        if (status === "Off") return "badge-secondary";
      } else if (label === "usage") {
        if (type == "items") {
          // console.log(this.items, '*** items')
          this.filterItems(this.payload, type);
        }
        if (status === "On") return "badge-success";
        if (status === "Off") return "badge-secondary";
        if (status === "") return "badge-secondary";
      }

      // console.log('에러 발생 후')
      // console.log(this.FauUsaitems)
      // return 'badge-secondary'
    },
    /**
     * 전체 코덱 사용현황 data set
     * @create 2021-04-26
     * @author 정한슬
     * @description 전체 코덱 사용현황 data set
     */
    setData(c) {
      let index = 0;
      if (this.item.length > 0) {
        this.item.find((item, i) => {
          if (c.data) {
            if (c.data.type) {
              if (c.data.type == "one") {
                if (item.ip === c.data.ip) {
                  index = i;
                  return i;
                } else {
                  index = null;
                  return index;
                }
              }
            }
          }
        });

        if (this.item[index] && index) {
          // console.log(c.data)
          // this.item[index].faultSt = '' // getcodec으로 데이터 불러왔을 시에 fault 데이터 남아있을 수 있으니 초기화
          if (c.data.diagnotics === "onoff") {
            this.item[index].onoffSt = c.data.status;
            this.item[index].lastUpdtPnttm = c.data.tm;
          } else if (c.data.diagnotics === "act") {
            this.item[index].onoffSt = "On";
            this.item[index].activationSt = c.data.status;
          } else if (c.data.diagnotics === "fault") {
            this.item[index].onoffSt = "On";
            this.item[index].faultSt = c.data.status;
          }
          this.payload.push(this.item[index]);
          let payloadNew = this.payload;
          this.payload = this.getUniqueObjectArray(payloadNew, "ip");
          this.items = this.payload;
        }
      }
    },
    /**
     * fault/usage data set
     * @create 2021-04-26
     * @author 정한슬
     * @description fault/usage data set
     */
    setFauUsaData(c) {
      // item.ip 와 c.data.ip 같지 않을때도 index = 0이기 때문에 0번째인 리스트가 계속 나옴
      // console.log('Codec Data **********************',c)

      let index = 0; // fault 및 usage / onoff 체크용
      let indexs = 0; // fault 및 usage 제거용
      if (this.FauUsaitem.length > 0) {
        indexs = this.FauUsaitem.find((FauUsaitem, i) => {
          if (c.data) {
            if (c.data.type) {
              if (c.data.type == "one") {
                if (FauUsaitem.ip === c.data.ip) {
                  // if (c.data.diagnotics !== "onoff") {
                  if (
                    FauUsaitem.activationSt === "On" ||
                    FauUsaitem.faultSt === "critical" ||
                    FauUsaitem.faultSt === "major" ||
                    FauUsaitem.faultSt === "minor" ||
                    FauUsaitem.faultSt === "warning"
                  ) {
                    index = i;
                    return index;
                  } else if (
                    FauUsaitem.activationSt === "Off" ||
                    FauUsaitem.faultSt === "" ||
                    FauUsaitem.faultSt === "cleared" ||
                    !FauUsaitem.faultSt
                  ) {
                    index = null;
                    indexs = i;
                    return indexs;
                  } else {
                    index = null;
                    return index;
                  }
                  // }
                } else {
                  index = null;
                  return index;
                }
              }
            }
          }
        });
        // console.log('**** total', c.data.status)
        if (this.FauUsaitem[index] && index) {
          // console.log(c.data.ip, c.data.diagnotics, "2");
          if (c.data.diagnotics === "onoff") {
            this.FauUsaitem[index].onoffSt = c.data.status;
            this.FauUsaitem[index].lastUpdtPnttm = c.data.tm;
            // console.log('onoffSt', c.data.status)
          } else if (c.data.diagnotics === "act") {
            this.FauUsaitem[index].onoffSt = "On";
            this.FauUsaitem[index].activationSt = c.data.status;
            // console.log('activationSt', c.data.status)
          } else if (c.data.diagnotics === "fault") {
            this.FauUsaitem[index].onoffSt = "On";
            this.FauUsaitem[index].faultSt = c.data.status;
            // console.log('faultSt', c.data.status)
          }
          this.payloads.push(this.FauUsaitem[index]);
          let payloadNew = this.payloads;
          this.payloads = this.getUniqueObjectArray(payloadNew, "ip");
          this.FauUsaitems = this.payloads;
        } else {
          // Usage / Fault Off 또는 Cleared 됬을 때 해당 리스트 비움
          // 현재 해당 부분 오류 있음
          // console.log('succues !!! ')
          // if(c.data.diagnotics){
          //   if (c.data.diagnotics==='act' || c.data.diagnotics==='fault') {
          //     for (let d = 0; d < this.FauUsaitems.length; d++) {
          //       const element = this.FauUsaitems[d];
          //       if(c.data.ip === element.ip){
          //         this.FauUsaitems.splice(d, 1)
          //         if(c.data.diagnotics === 'act'){
          //           this.FauUsaitem[d].activationSt = c.data.status
          //         }else{
          //           this.FauUsaitem[d].faultSt = c.data.status
          //         }
          //       }
          //     }
          //   }
          // }
        }
      }
    },
    /**
     *
     */
    filterItems(items, type) {
      // console.log('items function start', items)
      if (items.length > 0) {
        // console.log("& error 2")
        for (let index = 0; index < items.length; index++) {
          const element = items[index];
          // console.log(element, element.onoffSt, '************** end')
          if (type == "items") {
            // console.log(element)
            // console.log('444444444', DataTransferItemList)
            if (
              element.onoffSt == "" ||
              element.onoffSt == "Off" ||
              element.onoffSt == "off"
            ) {
              // console.log('111111111')
              this.payload.splice(index, 1);
              if (this.payloads.length > 0) {
                // console.log('*** error')
                this.filterItems(this.payloads, "faultItems");
              }
            }
          } else if (
            element.faultSt == "" ||
            element.faultSt == "Off" ||
            element.faultSt == "off"
          ) {
            if (
              element.activationSt == "" ||
              element.activationSt == "Off" ||
              element.activationSt == "off"
            ) {
              // console.log('222222222', )
              this.payloads.splice(index, 1);
            }
          } else if (
            element.activationSt == "" ||
            element.activationSt == "Off" ||
            element.activationSt == "off"
          ) {
            if (
              element.faultSt == "" ||
              element.faultSt == "Off" ||
              element.faultSt == "off"
            ) {
              // console.log('333333333')
              this.payloads.splice(index, 1);
            }
          }
        }
      }
      // console.log('error 3')
      // return items
    },
    /**
     * 웹소켓 connect
     * @create 2021-04-26
     * @author 정한슬
     * @description 웹소켓 connect
     */
    wsConect() {
      this.$options.sockets = new WebSocket(`${this.wsurl}`);
      // console.log(this.wsurl)
      const _self = this;
      this.$options.sockets.onopen = function (e) {
        // console.log('연결')
        // console.log(e)
      };
      this.$options.sockets.onmessage = (evt) => {
        // console.log(JSON.parse(evt.data))
        // console.log('************ 2')
        let payload = JSON.parse(evt.data);
        // console.log(payload);
        // console.log(payload)
        if (payload.api == "deleteFaults") {
          // 상세 클릭 시 웹소켓으로 sucess 받으면
          if (payload.returns.resultCode == "success") {
            // console.log(this.payloads.length, 'Fault Length &&')
            // console.log(this.FauUsaitems.length, 'FauUsaitems Length &&')
            if (this.FauUsaitems.length > 0) {
              for (let d = 0; this.FauUsaitems.length > d; d++) {
                // fault/usage list에서 fault 제거
                // console.log(payload.returns.data.ip, '****** payload let')
                // console.log(this.FauUsaitems[d].ip, '****** items let')
                if (
                  payload.returns.data.ip == this.FauUsaitems[d].ip &&
                  payload.returns.data.sn == this.FauUsaitems[d].sn
                ) {
                  // ip와 sn이 같을 때 제거
                  // console.log(d, this.FauUsaitems[d])
                  this.FauUsaitems[d].faultSt = "";
                  this.FauUsaitems.splice(d, 1); // fault/usage list에서 splice
                }
              }
              for (let t = 0; this.items.length > t; t++) {
                // 전체 list에서 fault 제거
                if (
                  payload.returns.data.ip == this.items[t].ip &&
                  payload.returns.data.sn == this.items[t].sn
                ) {
                  // ip와 sn이 같을 때 제거
                  this.items[t].faultSt = "";
                }
              }
            }
          }
        }
        // console.log('************* 1')

        this.setData(JSON.parse(evt.data));
        this.setFauUsaData(JSON.parse(evt.data));
        // if (JSON.parse(evt.data).data.ip == "222.121.149.25:9004") {
        //   console.log(JSON.parse(evt.data));
        // }
        this.setCodecTotalStatus(JSON.parse(evt.data));
        // console.log('************* 3', this.items)
      };

      this.$options.sockets.onclose = function (event) {
        setTimeout(function () {
          // console.log('재연결')
          _self.wsConect();
        }, 1000);
      };
      this.$options.sockets.onerror = function (err) {
        // console.log('에러')
        this.$options.sockets.close();
      };
    },
    /**
     * 서버 codec list get
     * @create 2021-04-26
     * @author 박재헌
     * @description 서버 codec list get
     */
    getCodec() {
      this.$nBack
        .post(this.codecUrl)
        .then((response) => {
          // console.log(response)
          if (response.data) {
            if (
              response.data.resultCode == "success" &&
              response.data.resultMessage == "성공"
            ) {
              this.item = response.data.data;
              this.FauUsaitem = response.data.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * 상세 에러 data set
     * @create 2021-04-26
     * @author 정한슬
     * @description 상세 에러 data set
     */
    setSettingProps(item, i) {
      this.configSt = false;
      const param = {
        ip: item.ip,
      };
      const codecUrl = "api/codec/configuration";
      this.$nBack({
        method: "post",
        url: codecUrl,
        data: qs.stringify(param),
      })
        .then((response) => {
          // console.log("*************************************", response.data.data[0].Configuration)
          if (response.data.resultCode === "success") {
            this.configSt = true;
            this.codecItem = response.data.data[0].Configuration;
          } else {
            this.configSt = false;
          }
          // alert('장비가 정상적으로 체크되었습니다.')
        })
        .catch(() => {
          this.configSt = false;
          // alert('장비 연결에 실패했습니다.')
        });
      this.detailItem = item;
    },
    /**
     * 상세 data set
     * @create 2021-04-26
     * @author 정한슬
     * @description 상세 data set
     */
    setDetailProps(item, i, tabs) {
      this.statusSt = false;
      this.detailItem = item;
      this.index = i;
      this.codecItemTypes = "";
      this.setTab = tabs;
      const codecUrl = "api/codec/status";
      const param = {
        ip: item.ip,
      };
      this.$nBack({
        method: "post",
        url: codecUrl,
        data: qs.stringify(param),
      })
        .then((response) => {
          if (response.data.resultCode === "success") {
            this.statusSt = true;
            this.codecItem = response.data.data[0];
            this.codecItemTypes = response.data.resultCode;
            this.loadingHide = true;
          } else {
            this.statusSt = false;
          }
          // console.log(response.data);

          // alert('장비가 정상적으로 체크되었습니다.')
        })
        .catch(() => {
          this.statusSt = false;
          this.loadingHide = true;
          this.codecItemTypes = "";
          // alert('장비 연결에 실패했습니다.')
        });
    },
    /**
     * array 중복 제거
     * @create 2021-04-26
     * @author 정한슬
     * @description array 중복 제거
     */
    getUniqueObjectArray(array, key) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return item[key] === item2[key];
          }) === i
        );
      });
    },
    /**
     * 코덱 장비 현황 통계
     * @create 2021-04-14
     * @author 박재헌
     * @param val 코덱 웹소켓상에서 받아오는 데이터 중 status 상세조회 데이터
     * 코덱 장비 현황 통계 조회
     */
    setCodecTotalStatus(val) {
      // console.log(val.data.payload);
      if (val.data) {
        if (val.data.payload) {
          if (val || val != undefined || val != "") {
            this.callTotal = val.data.payload.call.cnt;
            this.faultTotal = val.data.payload.fault.cnt;
            this.onoffTotal = val.data.payload.onoff.cnt;
            this.total = val.data.payload.total;
          } else {
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
<style scoped>
table {
  width: 100%;
  border: 1px solid #dfdfdf !important;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #dfdfdf !important;
  font-size: 13px !important;
}
td:nth-child(1) {
  text-align: center;
}
td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(5),
td:nth-child(6) {
  padding-left: 5px;
  padding-right: 5px;
}
td:nth-child(6) {
  font-size: 11px;
}
</style>