<template>
  <b-sidebar
    id="cd-stu-setting-new"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template>
      <!-- Header -->
      <!-- confereneceNm v-if="settingItems.data" 없는 경우 오류 해결 -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
        v-if="settingItems.data"
      >
        <h4 class="mb-0 font-weight-bolder">
          {{ settingItems.data.confereneceNm }} - IP (
          {{ settingItems.data.ip }} )
        </h4>
      </div>
      <!-- Header End -->
      <!-- Body -->
      <div class="pl-1 pr-1 pt-2">
        <div class="table-responsive" v-dragscroll>
          <table style="width: 100%">
            <tr style="height: 45px">
              <th
                class="text-center"
                style="border: 1px solid #ccc; width: 100px; font-weight: 600"
              >
                Protocol
              </th>
              <th
                class="text-center"
                style="border: 1px solid #ccc; width: 100px; font-weight: 600"
              >
                Port
              </th>
              <th
                class="text-center"
                style="border: 1px solid #ccc; width: 100px; font-weight: 600"
              >
                용도
              </th>
              <th
                class="text-center"
                style="border: 1px solid #ccc; width: 100px; font-weight: 600"
              >
                비고
              </th>
              <th
                class="text-center"
                style="border: 1px solid #ccc; width: 100px; font-weight: 600"
              >
                연결상태
              </th>
            </tr>
            <tr
              style="height: 45px"
              v-for="(item, i) in portItem"
              v-bind:key="i"
            >
              <td style="border: 1px solid #ccc" class="text-center">
                <span> {{ item.protocol }} </span>
              </td>
              <td style="border: 1px solid #ccc" class="text-center">
                <a
                  @click="
                    openPortChk(
                      settingItems.data.ip,
                      item.port,
                      item.protocolName,
                      item.sn
                    )
                  "
                >
                  {{ item.port }}
                </a>
              </td>
              <td style="border: 1px solid #ccc" class="text-center">
                <span> {{ item.usage }} </span>
              </td>
              <td style="border: 1px solid #ccc" class="text-center">
                <span> {{ item.bigo }} </span>
              </td>
              <td style="border: 1px solid #ccc" class="text-center">
                <span
                  class="b-avatar rounded-circle"
                  :class="setIcon(item.open)"
                ></span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Body End -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BButton,
  BTable,
  VBToggle,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

const qs = require("qs");

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BTable,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    settingItems: {
      data: Object,
      index: Object,
    },
    codecItem: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      portItem: [
        {
          sn: 1,
          protocolName: "tcp",
          protocol: "TCP",
          port: "22",
          usage: "SSH",
          bigo: "코덱 API",
          open: false,
        },
        {
          sn: 2,
          protocolName: "tcp",
          protocol: "TCP",
          port: "80",
          usage: "HTTP",
          bigo: "코덱 웹 페이지",
          open: false,
        },
        {
          sn: 3,
          protocolName: "tcp",
          protocol: "TCP",
          port: "443",
          usage: "HTTPS",
          bigo: "코덱 웹 페이지",
          open: false,
        },
        {
          sn: 4,
          protocolName: "tcp",
          protocol: "TCP",
          port: "5060",
          usage: "SIP",
          bigo: "SIP",
          open: false,
        },
        {
          sn: 5,
          protocolName: "tcp",
          protocol: "TCP",
          port: "5061",
          usage: "SIP",
          bigo: "SIP",
          open: false,
        },
        {
          sn: 6,
          protocolName: "tcp",
          protocol: "TCP",
          port: "1720",
          usage: "H.323",
          bigo: "H.323",
          open: false,
        },
        {
          sn: 7,
          protocolName: "udp",
          protocol: "UDP",
          port: "5060",
          usage: "SIP",
          bigo: "SIP",
          open: false,
        },
        {
          sn: 8,
          protocolName: "udp",
          protocol: "UDP",
          port: "1718",
          usage: "H.323",
          bigo: "H.323",
          open: false,
        },
        {
          sn: 9,
          protocolName: "udp",
          protocol: "UDP",
          port: "1719",
          usage: "H.323",
          bigo: "H.323",
          open: false,
        },
      ],
    };
  },
  methods: {
    openPortChk(ip, port, gubun, sn) {
      if (port != undefined) {
        this.$nBack({
          method: "get",
          url: `/api/codec/${gubun}/${ip}/${port}`,
        })
          .then((response) => {
            // console.log(response);
            if (response.data.isopen === true) {
              alert(`장비의 ${port} 포트 연결이 확인되었습니다.`);
              portItem[sn].open = true;
            } else {
              alert(`장비의 ${port} 포트 연결에 실패했습니다.`);
              portItem[sn].open = false;
            }
            // console.log(response.data);

            // alert('장비가 정상적으로 체크되었습니다.')
          })
          .catch(() => {
            alert("장비의 포트 연결에 실패했습니다.");
          });
      } else {
        alert("포트 정보가 없습니다.");
      }
    },
  },
  /**
   * 리스트 코덱 상태 뱃지 set
   * @create 2021-04-26
   * @author 박재헌
   * @description 리스트 코덱 상태 뱃지 set
   */
  setIcon(bool) {
    if (bool === null) return "badge-secondary";
    if (bool === false) return "badge-danger";
    if (bool === true) return "badge-success";
  },
};
</script>

<style>
</style>
