<template>
  <b-sidebar
    id="cd-stu-setting"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h4 class="mb-0 font-weight-bolder">
          {{ detailItems.confereneceNm }} - {{ detailItems.brandNm }}
          <span
            style="width: 1.5rem; height: 1.5rem; margin-top: -5px"
            class="b-avatar rounded-circle"
            :class="setConfigSt(configSt, 'onoff')"
          ></span>
        </h4>

        <!-- 초기값 세팅 Button -->
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            size="sm"
            style=""
            @click="setSettingBtn(hide)"
          >
            초기화
          </b-button> -->
        <!-- 초기값 세팅 Button End -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="mt-2 text-center"></div>
      <!-- Header End -->
      <!-- Body -->
      <b-form class="p-2" @submit.prevent>
        <!-- 초기값 설정 리스트 -->
        <b-table
          ref="refInvoiceListTable"
          :items="cdStuSettingCeItem"
          responsive
          :fields="cdStuSettingColums"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="조회된 목록이 없습니다."
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-setting text-lr"
        >
          <template slot="thead-top">
            <tr class="text-center">
              <th colspan="2">IP ({{ detailItems.ip }})</th>
            </tr>
          </template>
          <!-- <template slot="thead-middle">
                <tr class="text-center">
                  <th colspan="2">IP ({{ detailItems.ip }})</th>
                </tr>
              </template> -->
          <!-- 명령어 보기 Button -->
          <template #head(commend)>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              size="sm"
              style="margin-left: 50px"
              @click="setSettingBtn(hide)"
            >
              초기화
            </b-button>
            <!-- <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="btn-sm float-right"
                    @click="hide"
                    >
                        명령어 보기
                    </b-button> -->
          </template>
          <!-- 명령어 보기 Button END -->
        </b-table>
        <!-- 초기값 설정 리스트 END -->

        <!-- Form Actions -->
      </b-form>
      <!-- Body End -->
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BButton, BTable, VBToggle } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

const qs = require("qs");

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BTable,
  },
  props: {
    detailItems: {
      type: Object,
      default: () => [],
    },
    codecItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
    configSt: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      cdStuSettingCeItem: [
        {
          sn: 1,
          ce_ver: "암호화 모드",
          commend: "끄기",
        },
        {
          sn: 2,
          ce_ver: "자동응답 모드",
          commend: "켜기",
        },
        {
          sn: 3,
          ce_ver: "자동응답모드 마이크 음소거",
          commend: "켜기",
        },
        {
          sn: 4,
          ce_ver: "디폴트콜 프로토콜 지정",
          commend: "SIP",
        },
        {
          sn: 5,
          ce_ver: "H323 콜 셋업 모드",
          commend: "다이렉트",
        },
        {
          sn: 6,
          ce_ver: "네트워크 IP스택",
          commend: "IPv4",
        },
        {
          sn: 7,
          ce_ver: "네트워크서비스 H323 모드",
          commend: "켜기",
        },
        {
          sn: 8,
          ce_ver: "네트워크서비스 SIP 모드",
          commend: "켜기",
        },
        {
          sn: 9,
          ce_ver: "SIP 디폴트트랜스포트",
          commend: "UDP",
        },
        {
          sn: 10,
          ce_ver: "SIP 리슨포트 켜기/끄기",
          commend: "켜기",
        },
        {
          sn: 11,
          ce_ver: "유저인터페이스 정보 타입",
          commend: "SystemName",
        },
        {
          sn: 12,
          ce_ver: "OSD 암호화인디케이터",
          commend: "항상끄기",
        },
        {
          sn: 13,
          ce_ver: "인터페이스 웹엑스 참가 아이콘",
          commend: "숨기기",
        },
      ],
    };
  },
  watch: {
    detailItems(item) {
      if (item.versionNm === "TC") {
        this.cdStuSettingCeItem.splice(this.cdStuSettingCeItem.length - 1, 1);
      } else {
        this.cdStuSettingCeItem.push({
          sn: 13,
          ce_ver: "인터페이스 웹엑스 참가 아이콘",
          commend: "숨기기",
        });
      }

      this.cdStuSettingCeItem = this.getUniqueObjectArray(
        this.cdStuSettingCeItem,
        "sn"
      );
    },
  },
  setup() {
    const refInvoiceListTable = ref(null);
    const perPage = ref(10);
    const totalInvoices = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const cdStuSettingColums = [
      { key: "ce_ver", label: "초기설정값이 아래와 같이 변경됩니다." },
      { key: "commend", label: "" },
    ];

    const cdStuSettingTcItem = [
      {
        ce_ver: "암호화 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "자동응답 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "자동응답모드 마이크 음소거",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "디폴트콜 프로토콜 지정",
        commend: "Auto/SIP/H323/H320/Spark",
      },
      {
        ce_ver: "H323 콜 셋업 모드",
        commend: "다이렉트/게이트키퍼",
      },
      {
        ce_ver: "네트워크 IP스택",
        commend: "IPv4/IPv6/Dual",
      },
      {
        ce_ver: "네트워크서비스 H323 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "네트워크서비스 SIP 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "SIP 디폴트트랜스포트",
        commend: "UDP/TCP/TLS/AUTO",
      },
      {
        ce_ver: "SIP 리슨포트 켜기/끄기",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "유저인터페이스 정보 타입",
        commend: "SystemName",
      },
      {
        ce_ver: "OSD 암호화인디케이터",
        commend: "자동/항상켜기/항상끄기",
      },
      {
        ce_ver: "인터페이스 웹엑스 참가 아이콘",
        commend: "숨기기/보이기",
      },
    ];

    const resolveInvoiceStatusVariantAndIcon = (status) => {
      if (status === "Partial Payment")
        return { variant: "warning", icon: "PieChartIcon" };
      if (status === "On")
        return { variant: "success", icon: "CheckCircleIcon" };
      if (status === "On3")
        return { variant: "info", icon: "ArrowDownCircleIcon" };
      if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
      if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
      if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
      if (status === "On2")
        return { variant: "primary", icon: "CheckCircleIcon" };
      if (status === "") return { variant: "danger", icon: "InfoIcon" };
      return { variant: "secondary", icon: "XIcon" };
    };
    const resolveClientAvatarVariant = (status) => {
      if (status === "예약일자") return "primary";
      if (status === "예약시작") return "danger";
      if (status === "예약종료") return "secondary";
      if (status === "Draft") return "warning";
      if (status === "Sent") return "info";
      if (status === "Past Due") return "success";
      return "primary";
    };

    return {
      perPage,
      perPageOptions,
      // cdStuSettingCeItem,
      cdStuSettingTcItem,
      cdStuSettingColums,
      sortBy,
      isSortDirDesc,
      totalInvoices,
      currentPage,
      refInvoiceListTable,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
  methods: {
    /**
     * array 중복 제거
     * @create 2021-04-26
     * @author 정한슬
     * @description array 중복 제거
     */
    getUniqueObjectArray(array, key) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return item[key] === item2[key];
          }) === i
        );
      });
    },
    /**
     * 장비연결 상태 STATUS
     * @create 2021-04-14
     * @author 박재헌
     * @param {boolean} status
     * @param {string} label
     * @return {string}
     * @description 장비연결 상태 체크 아이콘
     * @comment status 값이 true일 시 초록색 아이콘 / false일 시 빨간색 아이콘
     */
    setConfigSt(status, label) {
      if (status === null) return "badge-danger";
      if (status === false) return "badge-danger";
      if (status === true) return "badge-success";
      return "badge-danger";
    },
    setSettingData() {},
    setSettingBtn(callback) {
      const codecUrl = `api/codec/defaultset`;
      const param = {
        ip: this.detailItems.ip,
      };
      this.$nBack({
        method: "post",
        contentType: "application/json; charset=utf-8",
        url: codecUrl,
        data: qs.stringify(param),
        dataType: "json",
      })
        .then((response) => {
          // console.log("*************************************", response.data)
          // console.log(param)
          if (response.data.resultCode === "success") {
            alert("초기값으로 설정되었습니다.");
          } else {
            alert(
              "초기값 설정에 실패하였습니다. 장비의 온오프상태를 확인해주세요."
            );
          }
          callback();
        })
        .catch((error) => {
          console.log(error);
          // console.log(param)
          alert(
            "초기값 설정에 실패하였습니다. 장비의 온오프상태를 확인해주세요."
          );
        });
    },
  },
};
</script>

<style>
</style>
