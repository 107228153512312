<template>
  <b-sidebar
    id="cd-stu-detail"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h4 class="mb-0 font-weight-bolder">
          코덱 상태 상세
          <span
            style="width: 1.5rem; height: 1.5rem; margin-top: -5px"
            class="b-avatar rounded-circle"
            :class="setStatusSt(statusSt, 'onoff')"
          ></span>
        </h4>
        <div class="text-right" style="margin-left: 120px">
          <!-- 초기값 세팅 Button -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            size="sm"
          >
            문자
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            size="sm"
          >
            전화
          </b-button> -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
            size="sm"
          >
            초기화
          </b-button> -->
          <!-- 초기값 세팅 Button End -->
        </div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="setHide(hide)"
        />
      </div>

      <!-- Header End -->
      <!-- 탭 메뉴 -->
      <b-col cols="12" md="12">
        <b-tabs class="ml-1" v-model="tabs">
          <b-tab title="Diagnostics" @click="clickTab(0)"> </b-tab>
          <b-tab title="상세" @click="clickTab(1)"> </b-tab>
          <b-tab title="System" @click="clickTab(2)"> </b-tab>
        </b-tabs>
      </b-col>

      <!-- Body -->

      <b-form class="pl-2 pr-2" @submit.prevent>
        <!-- 상세조회 테이블 -->
        <div v-if="tabs === 0">
          <button
            class="btn btn-primary mb-1 btn-sm float-right"
            @click="errorConfirm(detailItems.ip, detailItems.sn)"
          >
            에러확인
          </button>
          <div
            v-if="codecItem != 'undefined' && codecItemType === 'success'"
            class="float-left w-100"
          >
            <div v-if="codecItem.Status">
              <div v-if="codecItem.Status.Diagnostics">
                <div v-if="codecItem.Status.Diagnostics.Message">
                  <div v-if="codecItem.Status.Diagnostics.Message.length > 1">
                    <div
                      v-for="(item, i) in codecItem.Status.Diagnostics.Message"
                      :key="i"
                    >
                      <div
                        class="alert alert-error"
                        v-if="item.Level._text === 'Error'"
                      >
                        <strong>{{ item.Level._text }}:</strong>

                        <div>{{ item.Description._text }}</div>
                      </div>
                      <div class="alert" v-if="item.Level._text === 'Warning'">
                        <strong>{{ item.Level._text }}:</strong>

                        <div>{{ item.Description._text }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!codecItem.Status.Diagnostics.Message.length">
                    <div>
                      <!-- <div class="alert alert-error" v-if="item.Level._text === 'Error'">
                              <strong>{{codecItem.Status.Diagnostics.Message.Level._text}}:</strong> 
                              
                              <div>{{ codecItem.Status.Diagnostics.Message.Description._text }}</div>
                            </div> -->
                      <div
                        class="alert"
                        v-if="
                          codecItem.Status.Diagnostics.Message.Level._text ===
                          'Warning'
                        "
                      >
                        <strong
                          >{{
                            codecItem.Status.Diagnostics.Message.Level._text
                          }}:</strong
                        >

                        <div>
                          {{
                            codecItem.Status.Diagnostics.Message.Description
                              ._text
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="codecItem.Status">
              <div v-if="codecItem.Status.SystemUnit.Diagnostics">
                <div v-if="codecItem.Status.SystemUnit.Diagnostics.Message">
                  <div
                    v-if="
                      codecItem.Status.SystemUnit.Diagnostics.Message.length > 1
                    "
                  >
                    <div
                      v-for="(item, i) in codecItem.Status.SystemUnit
                        .Diagnostics.Message"
                      :key="i"
                    >
                      <div
                        class="alert alert-error"
                        v-if="item.Level._text === 'Error'"
                      >
                        <strong>{{ item.Level._text }}:</strong>

                        <div>{{ item.Description._text }}</div>
                      </div>
                      <div class="alert" v-if="item.Level._text === 'Warning'">
                        <strong>{{ item.Level._text }}:</strong>

                        <div>{{ item.Description._text }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      !codecItem.Status.SystemUnit.Diagnostics.Message.length
                    "
                  >
                    <div>
                      <!-- <div class="alert alert-error" v-if="item.Level._text === 'Error'">
                              <strong>{{codecItem.Status.Diagnostics.Message.Level._text}}:</strong> 
                              
                              <div>{{ codecItem.Status.Diagnostics.Message.Description._text }}</div>
                            </div> -->
                      <div
                        class="alert"
                        v-if="
                          codecItem.Status.SystemUnit.Diagnostics.Message.Level
                            ._text === 'Warning'
                        "
                      >
                        <strong
                          >{{
                            codecItem.Status.SystemUnit.Diagnostics.Message
                              .Level._text
                          }}:</strong
                        >

                        <div>
                          {{
                            codecItem.Status.SystemUnit.Diagnostics.Message
                              .Description._text
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center float-left w-100">
            데이터가 없습니다.
          </div>
        </div>
        <div v-if="tabs === 1">
          <table
            style="width: 100%; background: #fff"
            class="mb-1 table table-bordered"
          >
            <tr>
              <th>회의실명</th>
              <td>{{ detailItems.confereneceNm }}</td>
            </tr>
            <tr>
              <th>예약일자</th>
              <td>{{ detailItems.reservDt }}</td>
            </tr>
            <tr>
              <th>예약 시작일</th>
              <td>{{ detailItems.reservStartDt }}</td>
            </tr>
            <tr>
              <th>예약 종료일</th>
              <td>{{ detailItems.reservEndDt }}</td>
            </tr>
            <tr>
              <th>IP</th>
              <td>{{ detailItems.ip }}</td>
            </tr>
            <tr>
              <th>프로토콜</th>
              <td>{{ detailItems.protocol }}</td>
            </tr>
            <tr>
              <th>담당자</th>
              <td>{{ detailItems.manager }}</td>
            </tr>
            <tr>
              <th>권역 코드</th>
              <td>{{ detailItems.areaCode }}</td>
            </tr>
            <tr>
              <th>권역명</th>
              <td>{{ detailItems.areaNm }}</td>
            </tr>
            <tr>
              <th>회사명</th>
              <td v-if="detailItems.companyCode === '40001'">C10</td>
              <td v-else>C10</td>
            </tr>
            <tr>
              <th>서비스명</th>
              <td v-if="detailItems.serviceCode === '50001'">HD</td>
              <td v-else>SD</td>
            </tr>
            <tr>
              <th>브랜드명</th>
              <td>{{ detailItems.brandNm }}</td>
            </tr>
            <tr>
              <th>모델명</th>
              <td>{{ detailItems.ip }}</td>
            </tr>
          </table>
        </div>
        <!-- 상세조회 테이블 END -->
        <!-- 초기값 설정 리스트 -->
        <div
          class="card stu-detail-list"
          style="box-shadow: none !important"
          v-if="tabs === 2"
        >
          <div v-if="codecItem == 'undefined'">
            <div v-if="codecItem.Status" class="text-center">
              <div>
                <div class="card-header">
                  <h2 class="card-title">SystemUnit</h2>
                </div>
                <div class="tree-category">
                  <ul class="tree-category-content">
                    <li class="status-value">
                      <span class="status-label">ProductId : </span>
                      <span class="status-value">{{
                        codecItem.Status.SystemUnit.ProductId._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">ProductPlatform : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.ProductPlatform._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">ProductType : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.ProductType._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">Uptime : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.Uptime._text
                      }}</span>
                    </li>
                  </ul>
                </div>
                <div class="tree-category">
                  <div class="card-header">
                    <h3
                      class="corner-top tree-toggler tree-collapse card-title"
                    >
                      <span>Hardware</span>
                    </h3>
                  </div>
                  <ul class="tree-category-content">
                    <!-- <li class="status-value">
                    <span class="status-label">HasWifi : </span
                    ><span class="status-value">True / 없음</span>
                  </li> -->
                    <li class="status-value">
                      <span class="status-label"
                        >Monitoring Temperature Status : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.Hardware.Monitoring
                          .Temperature.Status._text
                      }}</span>
                    </li>
                    <!-- <li class="status-value">
                    <span class="status-label">MonitoringSoftware : </span
                    ><span class="status-value">43 / 없음</span>
                  </li> -->
                    <!-- <li class="status-value">
                    <span class="status-label">UDI : </span
                    ><span class="status-value">CS-KIT-MINI V01 FOC2417P8SF / 없음</span>
                  </li> -->
                    <li>
                      <div class="tree-level-1">MainBoard</div>
                      <ul class="level-1">
                        <!-- <li class="status-value">
                        <span class="status-label">Revision : </span
                        ><span class="status-value">E / 없음</span>
                      </li> -->
                        <!-- <li class="status-value">
                        <span class="status-label">SerialNumber : </span
                        ><span class="status-value">FOC2417NP10 / 없음</span>
                      </li> -->
                      </ul>
                    </li>
                    <li>
                      <div class="tree-level-1">Module</div>
                      <ul class="level-1">
                        <li class="status-value">
                          <span class="status-label">CompatibilityLevel : </span
                          ><span class="status-value">{{
                            codecItem.Status.SystemUnit.Hardware.Module
                              .CompatibilityLevel._text
                          }}</span>
                        </li>
                        <li class="status-value">
                          <span class="status-label">SerialNumber : </span
                          ><span class="status-value">{{
                            codecItem.Status.SystemUnit.Hardware.Module
                              .SerialNumber._text
                          }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="tree-category">
                  <div class="card-header">
                    <h3
                      class="corner-top tree-toggler tree-collapse card-title"
                    >
                      <span>Software</span>
                    </h3>
                  </div>
                  <ul class="tree-category-content">
                    <li class="status-value">
                      <span class="status-label">DisplayName : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.Software.DisplayName._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">Name : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.Software.Name._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">ReleaseDate : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.Software.ReleaseDate._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">Version : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.Software.Version._text
                      }}</span>
                    </li>
                    <li>
                      <div class="tree-level-1">OptionKeys</div>
                      <ul class="level-1">
                        <li class="status-value">
                          <span class="status-label">Encryption : </span
                          ><span class="status-value">{{
                            codecItem.Status.SystemUnit.Software.OptionKeys
                              .Encryption._text
                          }}</span>
                        </li>
                        <li class="status-value">
                          <span class="status-label">MultiSite : </span
                          ><span class="status-value">{{
                            codecItem.Status.SystemUnit.Software.OptionKeys
                              .MultiSite._text
                          }}</span>
                        </li>
                        <li class="status-value">
                          <span class="status-label">RemoteMonitoring : </span
                          ><span class="status-value">{{
                            codecItem.Status.SystemUnit.Software.OptionKeys
                              .RemoteMonitoring._text
                          }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="tree-category">
                  <div class="card-header">
                    <h3
                      class="corner-top tree-toggler tree-collapse card-title"
                    >
                      <span>State</span>
                    </h3>
                  </div>
                  <ul class="tree-category-content">
                    <li class="status-value">
                      <span class="status-label">NumberOfActiveCalls : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.State.NumberOfActiveCalls
                          ._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label"
                        >NumberOfInProgressCalls : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.State
                          .NumberOfInProgressCalls._text
                      }}</span>
                    </li>
                    <li class="status-value">
                      <span class="status-label">NumberOfSuspendedCalls : </span
                      ><span class="status-value">{{
                        codecItem.Status.SystemUnit.State.NumberOfSuspendedCalls
                          ._text
                      }}</span>
                    </li>
                    <!-- <li class="status-value">
                    <span class="status-label">Subsystem Application : </span
                    ><span class="status-value">Initialized / 없음</span>
                  </li> -->
                    <!-- <li class="status-value">
                    <span class="status-label">System : </span
                    ><span class="status-value">Initialized / 없음</span>
                  </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center">데이터가 없습니다.</div>
        </div>
        <!-- <b-table
          ref="refInvoiceListTable"
          :items="cdStuSettingCeItem"
          responsive
          :fields="cdStuSettingColums"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="조회된 목록이 없습니다."
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-setting text-left custom"
        > -->
        <!-- 명령어 보기 Button -->
        <!-- <template #head(commend)>
                    <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="btn-sm float-right"
                    @click="hide"
                    >
                        명령어 보기
                    </b-button>
                </template> -->
        <!-- 명령어 보기 Button END -->
        <!-- </b-table> -->
        <!-- 초기값 설정 리스트 END -->
      </b-form>
      <!-- Body End -->
    </template>
  </b-sidebar>
</template>
<style>
.custom.table-setting table tbody td {
  padding-left: 1.5rem !important;
  text-align: left;
}
.stu-detail-list ul {
  margin-top: 8px !important;
}
</style>
<script>
import {
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BTab,
  BCol,
  BTabs,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BTab,
    BCol,
    BTabs,
  },
  props: {
    detailItems: {
      type: Object,
      default: () => [],
    },
    codecItem: {
      type: Object,
    },
    setTab: {
      type: Number,
      default: 0,
    },
    codecItemType: {
      type: String,
      default: () => [],
    },
    statusSt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      tabs: 0,
      sockets: new WebSocket(this.wsurl),
    };
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  watch: {
    codecItem: function () {
      // console.log(this.codecItem)
      this.tabs = this.setTab;
    },
  },
  setup() {
    const refInvoiceListTable = ref(null);
    const perPage = ref(10);
    const totalInvoices = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const cdStuSettingColums = [
      { key: "ce_ver", label: "초기설정값이 아래와 같이 변경됩니다." },
      { key: "commend", label: "" },
    ];
    const cdStuSettingCeItem = [
      {
        ce_ver: "암호화 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "자동응답 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "자동응답모드 마이크 음소거",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "디폴트콜 프로토콜 지정",
        commend: "Auto/SIP/H323/H320/Spark",
      },
      {
        ce_ver: "H323 콜 셋업 모드",
        commend: "다이렉트/게이트키퍼",
      },
      {
        ce_ver: "네트워크 IP스택",
        commend: "IPv4/IPv6/Dual",
      },
      {
        ce_ver: "네트워크서비스 H323 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "네트워크서비스 SIP 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "SIP 디폴트트랜스포트",
        commend: "UDP/TCP/TLS/AUTO",
      },
      {
        ce_ver: "SIP 리슨포트 켜기/끄기",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "유저인터페이스 정보 타입",
        commend: "SystemName",
      },
      {
        ce_ver: "OSD 암호화인디케이터",
        commend: "자동/항상켜기/항상끄기",
      },
      {
        ce_ver: "인터페이스 웹엑스 참가 아이콘",
        commend: "숨기기/보이기",
      },
    ];

    const cdStuSettingTcItem = [
      {
        ce_ver: "암호화 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "자동응답 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "자동응답모드 마이크 음소거",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "디폴트콜 프로토콜 지정",
        commend: "Auto/SIP/H323/H320/Spark",
      },
      {
        ce_ver: "H323 콜 셋업 모드",
        commend: "다이렉트/게이트키퍼",
      },
      {
        ce_ver: "네트워크 IP스택",
        commend: "IPv4/IPv6/Dual",
      },
      {
        ce_ver: "네트워크서비스 H323 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "네트워크서비스 SIP 모드",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "SIP 디폴트트랜스포트",
        commend: "UDP/TCP/TLS/AUTO",
      },
      {
        ce_ver: "SIP 리슨포트 켜기/끄기",
        commend: "켜기/끄기",
      },
      {
        ce_ver: "유저인터페이스 정보 타입",
        commend: "SystemName",
      },
      {
        ce_ver: "OSD 암호화인디케이터",
        commend: "자동/항상켜기/항상끄기",
      },
      {
        ce_ver: "인터페이스 웹엑스 참가 아이콘",
        commend: "숨기기/보이기",
      },
    ];

    const resolveInvoiceStatusVariantAndIcon = (status) => {
      if (status === "Partial Payment")
        return { variant: "warning", icon: "PieChartIcon" };
      if (status === "On")
        return { variant: "success", icon: "CheckCircleIcon" };
      if (status === "On3")
        return { variant: "info", icon: "ArrowDownCircleIcon" };
      if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
      if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
      if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
      if (status === "On2")
        return { variant: "primary", icon: "CheckCircleIcon" };
      if (status === "") return { variant: "danger", icon: "InfoIcon" };
      return { variant: "secondary", icon: "XIcon" };
    };
    const resolveClientAvatarVariant = (status) => {
      if (status === "예약일자") return "primary";
      if (status === "예약시작") return "danger";
      if (status === "예약종료") return "secondary";
      if (status === "Draft") return "warning";
      if (status === "Sent") return "info";
      if (status === "Past Due") return "success";
      return "primary";
    };

    return {
      perPage,
      perPageOptions,
      cdStuSettingCeItem,
      cdStuSettingTcItem,
      cdStuSettingColums,
      sortBy,
      isSortDirDesc,
      totalInvoices,
      currentPage,
      refInvoiceListTable,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
  mounted() {
    this.sockets; // detail page socket 연결
  },
  methods: {
    /**
     * 장비연결 상태 CONFIGURATION
     * @create 2021-04-14
     * @author 박재헌
     * @param {boolean} status
     * @param {string} label
     * @return {string}
     * @description 장비연결 상태 체크 아이콘
     * @comment status 값이 true일 시 초록색 아이콘 / false일 시 빨간색 아이콘
     */
    setStatusSt(status, label) {
      if (status === null) return "badge-danger";
      if (status === false) return "badge-danger";
      if (status === true) return "badge-success";
      return "badge-danger";
    },
    /**
     * 에러 확인
     * @create 2021-04-23
     * @author 정한슬
     * @param {string} ip
     * @param {string} sn
     * @description 에러 확인 후 서버에 ip, sn 보냄
     */
    errorConfirm(ip, sn) {
      const cf = confirm("에러가 해결되었습니까?");
      if (cf) {
        let sendData = {
          ip: ip,
          sn: sn,
          status: "faultDel",
        };
        if (this.sockets.readyState === 1) {
          // console.log("성공", sendData)
          this.sockets.send(JSON.stringify(sendData));
        } else {
          alert("장비 연결에 실패했습니다.");
          return false;
        }
      }
    },
    clickTab(tab) {
      this.tabs = tab;
    },
    setHide(callback) {
      this.tabs = 0;
      callback();
    },
  },
};
</script>
